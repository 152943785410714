import { Box, Divider, Link, ListItemIcon, ListItemText, MenuItem, Popover, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { contentBoxStyle } from "../styles/styles";
import { DateRangeSelect } from "../components/DateSelect";
import { DataGridPro, GridColDef, GridFilterItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { ExportToolbar } from "./ClientReportGrid";
import { localeText } from "../misc/LocaleText";
import { useEffect, useRef, useState } from "react";
import { format, set, subDays } from "date-fns";
import LogitarApi from "../api/LogitarApi";
import { flushSync } from "react-dom";
import MapViewer from "../components/map/MapViewer";
import { VehiclePosition } from "./MapView";
import { VehicleLocationPath } from "../api/LogitarApiTypes";
import { Checklist, Map } from "@mui/icons-material";

export const formatDuration = (duration: number) => {
    const az = (v: number) => v > 9 ? v.toString() : ('0' + v);
    let sign = duration < 0 ? "-" : "";
    if (duration < 0) {
        duration = Math.abs(duration);
    }

    return `${sign}${az(Math.floor(duration / 3600000))}:${az(Math.floor(duration / 60000) % 60)}:${az(duration / 1000 % 60)}`;
}

const jobReportColumns: GridColDef[] = [
    { field: 'date', headerName: 'Ajopvm', width: 100, valueFormatter: (params) => format(params.value as Date, "dd.MM.yyyy") },
    { field: 'shift', headerName: 'Vuoro', width: 30 },
    { field: 'itemName', headerName: 'Nimike', maxWidth: 450, valueGetter: (params) => params.row.item + ":" + params.row.itemName },
    { field: 'cargoLabel', headerName: 'Tuote' },
    { field: 'tons', headerName: 'Tonnit' },
    { field: 'vehicle', headerName: 'Auto', valueGetter: (params) => params.row.vehicle + ":" + params.row.licenseNumber },
    { field: 'driver', headerName: 'Kuljettaja', valueGetter: (params) => params.row.driver ? (params.row.driver + ":" + params.row.driverName) : "" },
    { field: 'gpsTransitionTime', headerName: 'Siirtymäaika', valueFormatter: (params) => formatDuration(params.value as number) },
    { field: 'gpsLoadTime', headerName: 'Lastausaika', valueFormatter: (params) => formatDuration(params.value as number) },
    { field: 'gpsTransportTime', headerName: 'Kuljetusaika', valueFormatter: (params) => formatDuration(params.value as number) },
    { field: 'gpsUnloadTime', headerName: 'Purkuaika', valueFormatter: (params) => formatDuration(params.value as number) },
    { field: 'estimate', headerName: 'Arvioitu', valueFormatter: (params) => formatDuration(params.value as number) },
    {
        field: 'gpsTotalTime',
        headerName: 'Toteutunut',
        valueFormatter: (params) => formatDuration(params.value as number),
        cellClassName: (params) => params.row.jobDurationClass,
        renderCell: (params) => {

            const tooltip = (
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell size="small" variant="head">Ero</TableCell>
                            <TableCell size="small">{params.row.gpsTotalTime - params.row.estimate >= 0 ? '+' : ''}{formatDuration(params.row.gpsTotalTime - params.row.estimate)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell size="small" variant="head">Kesto huomioimatta vuoroja</TableCell>
                            <TableCell size="small">{formatDuration(params.row.originalJobDuration)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )

            return <Tooltip title={tooltip}>
                <div>{formatDuration(params.row.gpsTotalTime)}</div>
            </Tooltip>
        }
    },


];


export default function JobReports() {

    const searchParams = new URL(window.location.href).searchParams;

    const [dateRange, setDateRange] = useState<[Date, Date]>((searchParams.has("start") && searchParams.has("end")) ? 
                                                                [new Date(searchParams.get("start") || ""), new Date(searchParams.get("end") || "")] : 
                                                                [subDays(new Date(), 7), new Date()]);

    const [jobs, setJobs] = useState<any[]>([]);
    const [initialFetch, setInitialFetch] = useState<boolean>(true);

    const [popupAnchor, setPopupAnchor] = useState<HTMLElement | null>(null);
    const [popupJob, setPopupJob] = useState<any | null>(null);
    const [popupNextJob, setPopupNextJob] = useState<any | null>(null);
    const [popupMouse, setPopupMouse] = useState<{ x: number, y: number } | null>(null);

    // Stores the latest positions for every vehicle, just for the map popup
    const [vehiclePositions, setVehiclePositions] = useState<VehiclePosition[]>([]);
    const [vehiclePath, setVehiclePath] = useState<VehicleLocationPath | null>(null);
    const [vehicleCurrentPos, setVehicleCurrentPos] = useState<number>(0);
    const vehicleCurrentPosRef = useRef<number>(0);
    const [animateVehicle, setAnimateVehicle] = useState<boolean>(false);
    const animateVehicleRef = useRef<boolean>(false);
    const animationFrameRef = useRef<number | null>(null);
    const [mapLabel, setMapLabel] = useState<string | null>(null);

    const apiRef = useGridApiRef();

    useEffect(() => {
        LogitarApi.getVehicleLocations(null, null).then((r) => {
            setVehiclePositions((r.vehicles as VehiclePosition[]).map((v) => {
                return {
                    ...v,
                    job: null
                }
            }));
        }).catch((e) => {
            console.log(e);
        });

        return () => {
            if(animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
                animationFrameRef.current = null;
            }
        }

    }, []);

    const animateVehiclePos = () => {
        if (animateVehicleRef.current) {
            if (vehiclePath) {
                const nextPos = vehicleCurrentPosRef.current < vehiclePath.positions.length - 1 ? vehicleCurrentPosRef.current + 1 : 0;
                setVehicleCurrentPos(nextPos);
                if(vehiclePath.positions[nextPos]) {
                    setMapLabel(format(new Date(vehiclePath.positions[nextPos].dateTime + "Z"), "dd.MM HH:mm"));
                }

                animationFrameRef.current = requestAnimationFrame(animateVehiclePos);
            }
        }
    }

    useEffect(() => {
        if (animateVehicle) {
            if (!animationFrameRef.current) {
                animationFrameRef.current = requestAnimationFrame(animateVehiclePos);
            }
        }
        else {
            if(animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
                animationFrameRef.current = null;
            }
        }

        animateVehicleRef.current = animateVehicle;

    }, [animateVehicle]);

    useEffect(() => {
        const vehIndex = vehiclePositions.findIndex(v => v.vehicle === vehiclePath?.vehicle.id);

        if (vehIndex >= 0) {
            setVehiclePositions(vehiclePositions.map((v, i) => {
                if (i === vehIndex) {
                    return {
                        ...v,
                        position: {
                            ...v.position,
                            latitude: vehiclePath?.positions[vehicleCurrentPos].latitude || 0,
                            longitude: vehiclePath?.positions[vehicleCurrentPos].longitude || 0,
                            speed: vehiclePath?.positions[vehicleCurrentPos].speed || 0,
                            dateTime: vehiclePath?.positions[vehicleCurrentPos].dateTime || ""
                        }
                    }
                }
                return v;
            }))
        }

        vehicleCurrentPosRef.current = vehicleCurrentPos;

    }, [vehicleCurrentPos]);

    useEffect(() => {
        
        LogitarApi.getJobs({
            timeFrame: {
                start: dateRange[0],
                end: dateRange[1]
            },
            extent: "alljoin"
        }).then(async (r) => {

            // Fetch workhours
            const workHours = ((await LogitarApi.getWorkHours(dateRange[0], dateRange[1])) as any).workhours as any[];

            const nextJobs: any[] = [];

            // Must be reversed to get the correct NEXT job for the vehicle
            const rows = r.jobs.filter((e: any) => e.state >= 3).reverse().map((e: any, i: number) => {
                // Get previous job for this vehicle
                const nextJob = nextJobs.reverse().find((j: any) => j.vehicle === e.vehicle);

                const date: Date = new Date(e.date);
                const loadStart: Date = new Date(e.gpsLoadStart + "Z");
                const loadEnd: Date = new Date(e.gpsLoadEnd + "Z");
                const unloadStart: Date = new Date(e.gpsUnloadStart + "Z");
                const unloadEnd: Date = new Date(e.gpsUnloadEnd + "Z");
                const nextLoadStart: Date | null = (nextJob && nextJob.gpsLoadStart) ? new Date(nextJob.gpsLoadStart + "Z") : null;
                const hours: number = Number(e.itemHours);

                // Filter workhours for this vehicle
                const vehicleWorkHours = workHours.flatMap(w => w.workhours).filter((w) => w.vehicle === e.vehicle).map(wh => {
                    return {
                        startTime: wh.startTime ? new Date(wh.startTime) : null,
                        endTime: wh.endTime ? new Date(wh.endTime) : null
                    }
                });
                
                // NOTE: At the moment there are a lot of negative durations, Math.max is used to prevent them
                // Remove Math.max after re-calculating durations is done
                let transitionDuration: number = Math.max((nextLoadStart && e.gpsLoadEnd) ? (nextLoadStart.getTime() - loadEnd.getTime()) : 0, 0);

                let loadDuration: number = (e.gpsLoadStart && e.gpsLoadEnd) ? (loadEnd.getTime() - loadStart.getTime()) : 0;
                let transportDuration: number = (e.gpsLoadEnd && e.gpsUnloadStart) ? (unloadStart.getTime() - loadEnd.getTime()) : 0;
                let unloadDuration: number = (e.gpsUnloadStart && e.gpsUnloadEnd) ? (unloadEnd.getTime() - unloadStart.getTime()) : 0;
                let jobDuration: number = (e.gpsLoadStart && e.gpsUnloadEnd) ? (unloadEnd.getTime() - loadStart.getTime()) : 0;
                if(nextLoadStart && e.gpsLoadStart && e.gpsUnloadEnd && unloadEnd.getTime() < nextLoadStart.getTime()) {
                    jobDuration = (nextLoadStart.getTime() - loadStart.getTime());
                }

                let originalJobDuration: number = jobDuration;

                // Adjust durations so that they are during work hours
                if(nextLoadStart && e.gpsUnloadEnd) {
                    let realTransitionDuration = 0;
                    vehicleWorkHours.forEach((wh) => {
                        if(!wh.startTime || !wh.endTime)
                            return;

                        if(unloadEnd.getTime() < wh.endTime.getTime() && nextLoadStart.getTime() > wh.startTime.getTime()) {
                            realTransitionDuration += Math.min(nextLoadStart.getTime(), wh.endTime.getTime()) - Math.max(unloadEnd.getTime(), wh.startTime.getTime());
                        }
                    });
                    transitionDuration = Math.max(realTransitionDuration, 0);
                }
                if(e.gpsLoadEnd && e.gpsLoadStart) {
                    let realLoadDuration = 0;
                    vehicleWorkHours.forEach((wh) => {
                        if(!wh.startTime || !wh.endTime)
                            return;

                        if(loadStart.getTime() < wh.endTime.getTime() && loadEnd.getTime() > wh.startTime.getTime()) {
                            realLoadDuration += Math.min(loadEnd.getTime(), wh.endTime.getTime()) - Math.max(loadStart.getTime(), wh.startTime.getTime());
                        }
                    });
                    loadDuration = realLoadDuration;
                }
                if(e.gpsUnloadEnd && e.gpsUnloadStart) {
                    let realUnloadDuration = 0;
                    vehicleWorkHours.forEach((wh) => {
                        if(!wh.startTime || !wh.endTime)
                            return;

                        if(unloadStart.getTime() < wh.endTime.getTime() && unloadEnd.getTime() > wh.startTime.getTime()) {
                            realUnloadDuration += Math.min(unloadEnd.getTime(), wh.endTime.getTime()) - Math.max(unloadStart.getTime(), wh.startTime.getTime());
                        }
                    });
                    unloadDuration = realUnloadDuration;
                }
                if(e.gpsLoadEnd && e.gpsUnloadStart) {
                    let realTransportDuration = 0;
                    vehicleWorkHours.forEach((wh) => {
                        if(!wh.startTime || !wh.endTime)
                            return;

                        if(loadEnd.getTime() < wh.endTime.getTime() && unloadStart.getTime() > wh.startTime.getTime()) {
                            realTransportDuration += Math.min(unloadStart.getTime(), wh.endTime.getTime()) - Math.max(loadEnd.getTime(), wh.startTime.getTime());
                        }
                    });
                    transportDuration = realTransportDuration;
                }
                // Check if previous job end time is available and it is before this job start time
                if(nextLoadStart && e.gpsLoadStart && e.gpsUnloadEnd && unloadEnd.getTime() < nextLoadStart.getTime()) {
                    let realJobDuration = 0;
                    vehicleWorkHours.forEach((wh) => {
                        if(!wh.startTime || !wh.endTime)
                            return;

                        if(loadStart.getTime() < wh.endTime.getTime() && nextLoadStart.getTime() > wh.startTime.getTime()) {
                            realJobDuration += Math.min(nextLoadStart.getTime(), wh.endTime.getTime()) - Math.max(loadStart.getTime(), wh.startTime.getTime());
                        }
                    });
                    jobDuration = realJobDuration;
                }
                // Otherwise just use current job duration
                else if(e.gpsLoadStart && e.gpsUnloadEnd) {
                    let realJobDuration = 0;
                    vehicleWorkHours.forEach((wh) => {
                        if(!wh.startTime || !wh.endTime)
                            return;

                        if(loadStart.getTime() < wh.endTime.getTime() && unloadEnd.getTime() > wh.startTime.getTime()) {
                            realJobDuration += Math.min(unloadEnd.getTime(), wh.endTime.getTime()) - Math.max(loadStart.getTime(), wh.startTime.getTime());
                        }
                    });
                    jobDuration = realJobDuration;
                }

                const estimateJobDuration: number = (e.estDuration ? Number(e.estDuration) : hours) * 3600000;
                const expectedJobDuration: number = hours * 3600000;

                const jobDurationClass: string = jobDuration !== 0 ? (jobDuration <= expectedJobDuration ? "default-green" : "default-red") : "";

                nextJobs.push(e);

                return {
                    ...e,
                    id: e.id,
                    date: date,
                    shift: e.shift,
                    itemName: e.name,
                    vehicle: e.vehicle,
                    licenseNumber: e.licenseNumber,
                    driver: e.user,
                    driverName: e.usersName,
                    gpsTransitionTime: transitionDuration,
                    gpsLoadTime: loadDuration,
                    gpsTransportTime: transportDuration,
                    gpsUnloadTime: unloadDuration,
                    estimate: estimateJobDuration,
                    gpsTotalTime: jobDuration,
                    originalJobDuration: originalJobDuration,
                    jobDurationClass: jobDurationClass
                }
            }).reverse();

            // Set filter model on the first fetch
            if(initialFetch) {
                const filterItems: GridFilterItem[] = [];
                // Add vehicle filter
                if(searchParams.has("vehicle")) {
                    const v = searchParams.get("vehicle") || "";
                    filterItems.push({
                        field: "vehicle",
                        operator: "startsWith",
                        value: v + ":"
                    });
                }
                // Add item filter
                if(searchParams.has("item")) {
                    const i = searchParams.get("item") || "";
                    filterItems.push({
                        field: "itemName",
                        operator: "startsWith",
                        value: i + ":"
                    });
                }

                apiRef.current.setFilterModel({
                    items: filterItems
                });

                setInitialFetch(false);
            }

            setJobs(rows);
        }).catch((e) => {
            console.log(e);
        });

    }, [dateRange]);

    useEffect(() => {
        setTimeout(() => {
            flushSync(() => {
                apiRef.current.updateRows(jobs);
                apiRef.current.autosizeColumns({ columns: ["itemName", "cargoLabel"], includeHeaders: true, includeOutliers: true });
            })
        }, 100);
    }, [jobs]);

    const mapLink = () => {
        if(!popupJob)
            return "";
        
        const date = format(new Date(popupJob.date), "yyyy-MM-dd");

        return `/mapview?vehicle=${popupJob.vehicle}&date=${date}`;
    }

    const checkupLink = () => {
        if(!popupJob)
            return "";
        
        const date = format(new Date(popupJob.date), "yyyy-MM-dd");

        return `/checkup?item=${popupJob.item}&vehicle=${popupJob.vehicle}&startDate=${date}&endDate=${date}&checkState=3`;
    }


    const selVeh = vehiclePositions.find(v => v.vehicle === popupJob?.vehicle);
    let selVehLoadStart: string | null = null;
    let selVehLoadEnd: string | null = null;
    let selVehUnloadStart: string | null = null;
    let selVehUnloadEnd: string | null = null;

    let depCoordinates: { latitude: number, longitude: number, radius: number } = {
        latitude: 0,
        longitude: 0,
        radius: 0
    };
    let arrCoordinates: { latitude: number, longitude: number, radius: number } = {
        latitude: 0,
        longitude: 0,
        radius: 0
    };

    if(popupJob && selVeh) {
        selVehLoadStart = (popupJob.gpsLoadStart && new Date(popupJob.gpsLoadStart).getTime() < new Date(selVeh.position.dateTime).getTime()) ? popupJob.gpsLoadStart : null;
        selVehLoadEnd = (popupJob.gpsLoadEnd && new Date(popupJob.gpsLoadEnd).getTime() < new Date(selVeh.position.dateTime).getTime()) ? popupJob.gpsLoadEnd : null;
        selVehUnloadStart = (popupJob.gpsUnloadStart && new Date(popupJob.gpsUnloadStart).getTime() < new Date(selVeh.position.dateTime).getTime()) ? popupJob.gpsUnloadStart : null;
        selVehUnloadEnd = (popupJob.gpsUnloadEnd && new Date(popupJob.gpsUnloadEnd).getTime() < new Date(selVeh.position.dateTime).getTime()) ? popupJob.gpsUnloadEnd : null;
        
        const parsedDepCoords = popupJob.depCoordinates.split(',');
        const parsedArrCoords = popupJob.arrCoordinates.split(',');
        depCoordinates = {
            latitude: parseFloat(parsedDepCoords[0]),
            longitude: parseFloat(parsedDepCoords[1]),
            radius: Number(popupJob.depRadius)
        };

        arrCoordinates = {
            latitude: parseFloat(parsedArrCoords[0]),
            longitude: parseFloat(parsedArrCoords[1]),
            radius: Number(popupJob.arrRadius)
        };
    }


    return (
        <Box sx={{ ...contentBoxStyle, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }} className={"report-grid"}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{maxWidth: 500}}>
                    <DateRangeSelect
                        value={dateRange}
                        onChange={(v) => { if (v[0] && v[1]) setDateRange(v as [Date, Date]) }}
                        labels={{ start: "Ajopvm alku", end: "Ajopvm loppu" }}
                    />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Box>
                    <Link href={"/gpsrerunner"}>Korjaa GPS lastaus- ja purkuajat</Link>
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", height: "calc(100% - 56px)" }}>
                <DataGridPro
                    slotProps={{
                        toolbar: {
                            //onPrint: (ids) => { setReportIds(ids); setPrintTriggered(true) }
                        }
                    }}
                    unstable_headerFilters
                    columns={jobReportColumns}
                    rows={jobs}
                    loading={false}
                    density="compact"
                    localeText={localeText}
                    hideFooter
                    sx={{ flexGrow: 1 }}
                    apiRef={apiRef}
                    onRowClick={(params, event) => {
                        setPopupJob(params.row);
                        setPopupAnchor(event.currentTarget);
                        setPopupMouse({ x: event.clientX, y: event.clientY });
                        
                        // Find next popup job
                        let nextJob : any | null = null;

                        const filteredJobs = jobs.filter(e => e.vehicle === params.row.vehicle);
                        const nextJobIndex = filteredJobs.findIndex(j => j.id === params.row.id) + 1;
                        if(nextJobIndex >= 0 && nextJobIndex < filteredJobs.length) {
                            nextJob = filteredJobs[nextJobIndex];
                        }
                        
                        setPopupNextJob(nextJob);
                        console.log(params.row);

                        // Fetch vehicle path
                        // GPS times
                        const gpsLoadStart = params.row.gpsLoadStart ? new Date(params.row.gpsLoadStart) : null;
                        const gpsUnloadEnd = nextJob ? (nextJob.gpsLoadStart ? new Date(nextJob.gpsLoadStart) : null) : 
                                                        (params.row.gpsUnloadEnd ? new Date(params.row.gpsUnloadEnd) : null);
                        // Inserted times
                        const insStart = params.row.loadTime ? new Date(params.row.loadTime) : null;
                        const insEnd = params.row.unloadTime ? new Date(params.row.unloadTime) : null;
                        // Use GPS times if available
                        if ((gpsLoadStart || insStart) && (gpsUnloadEnd || insEnd)) {

                            LogitarApi.getVehicleLocations(params.row.vehicle, {
                                start: (gpsLoadStart || insStart) as Date,
                                end: (gpsUnloadEnd || insEnd) as Date
                            }).then((r) => {
                                if (r.vehicles.length > 0) {
                                    const retVehicles = r.vehicles as VehicleLocationPath[];
                                    if(retVehicles[0].positions.length > 0) {
                                        setVehiclePath(retVehicles[0]);
                                        setVehicleCurrentPos(0);

                                        setAnimateVehicle(true);
                                    }
                                }
                            }).catch((e) => {
                                console.log(e);
                            });
                        }

                    }}
                />
                <Popover
                    open={popupAnchor !== null}
                    anchorEl={popupAnchor}
                    anchorReference="anchorPosition"
                    anchorPosition={popupMouse ? { top: popupMouse.y, left: popupMouse.x } : undefined}
                    onClose={() => {
                        setPopupAnchor(null);
                        setPopupJob(null);
                        setPopupNextJob(null);
                        setVehiclePath(null);
                        setAnimateVehicle(false);
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >

                        <Box
                            sx={{
                                minWidth: 200
                            }}
                        >
                            {
                                (vehiclePath && vehiclePath.positions.length > 0) &&
                                <>
                                    <MapViewer
                                        style={{
                                            width: 200,
                                            height: 200
                                        }}
                                        initialCenter={selVeh ? { lat: selVeh.position.latitude, lng: selVeh.position.longitude } : undefined}
                                        vehicles={selVeh ? [
                                            {
                                                ...selVeh,
                                                job: popupJob ? {
                                                    id: popupJob.id,
                                                    gpsLoadStart: selVehLoadStart,
                                                    gpsLoadEnd: selVehLoadEnd,
                                                    gpsUnloadStart: selVehUnloadStart,
                                                    gpsUnloadEnd: selVehUnloadEnd,
                                                    loadStart: popupJob.loadTime,
                                                    loadEnd: popupJob.unloadTime,
                                                    item: {
                                                        id: popupJob.item,
                                                        itemNumber: popupJob.itemNumber,
                                                        name: popupJob.itemName,
                                                        shortName: popupJob.itemName,
                                                        departure: depCoordinates,
                                                        arrival: arrCoordinates
                                                    }
                                                } : null
                                            }
                                        ] : []}
                                        initialBounds={vehiclePath ? vehiclePath.positions.map(e => ({ lat: e.latitude, lng: e.longitude })) : undefined}
                                        vehiclePath={vehiclePath?.positions}
                                        mapLabel={mapLabel}
                                        areas={[
                                            ...[
                                                {
                                                    name: popupJob.depName,
                                                    coordinates: popupJob.depCoordinates,
                                                    radius: popupJob.depRadius,
                                                    label: 'A'
                                                },
                                                {
                                                    name: popupJob.arrName,
                                                    coordinates: popupJob.arrCoordinates,
                                                    radius: popupJob.arrRadius,
                                                    label: 'B'
                                                }
                                            ],
                                            ...(popupNextJob ? [
                                                {
                                                    name: popupNextJob.depName,
                                                    coordinates: popupNextJob.depCoordinates,
                                                    radius: popupNextJob.depRadius,
                                                    label: 'C'
                                                }
                                            ] : [])
                                        ]}
                                        mapLabelStyle={{
                                            top: 2,
                                            left: 2,
                                            fontSize: '0.75em'
                                        }}
                                        mapProps={{
                                            mapTypeControl: false
                                        }}
                                    />
                                    <Divider orientation="vertical" flexItem />
                                </>
                            }
                        </Box>
                        {
                            <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                                <MenuItem
                                    component={Link}
                                    href={mapLink()}
                                    target={"_blank"}
                                    role="link"
                                >
                                    <ListItemIcon>
                                        <Map />
                                    </ListItemIcon>
                                    <ListItemText>Kartta</ListItemText>
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    href={checkupLink()}
                                    target={"_blank"}
                                    role="link"
                                >
                                    <ListItemIcon>
                                        <Checklist />
                                    </ListItemIcon>
                                    <ListItemText>Ajetut/Tarkastus</ListItemText>
                                </MenuItem>
                            </Box>
                        }
                    </Box>
                </Popover>
            </Box>
        </Box>
    );

}