import { ListAlt, Print } from "@mui/icons-material";
import { Autocomplete, Button, Chip, Divider, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, subDays } from "date-fns";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import LogitarApi from "../api/LogitarApi";
import { DateRangeSelect } from "../components/DateSelect";
import PrintableReportBase from "../components/PrintableReportBase";
import EmissionsOverview from "../components/emissionreports/EmissionsOverview";
import { DataGridPro, gridFilteredSortedRowIdsSelector, useGridApiRef } from "@mui/x-data-grid-pro";
import LogiTarUser from "../misc/User";
import { FuelType, fuelTypeList } from "../components/FuelPriceCard";
import EmissionMultipliers from "../components/EmissionMultipliers";
import { calculateEmissionsForFuel, calculateEmissionSummary, isJobInvalid, renderRowInvalidReason } from "../components/emissionreports/EmissionReportHelpers";
import CustomToolbar from "../components/CustomToolbar";
import { UserConfig, useUserConfig } from "../misc/UserConfig";
import { localeText } from "../misc/LocaleText";

// REPLACE WITH ACCURATE VALUES
// Current source: https://tieto.traficom.fi/fi/paketti-ja-kuorma-autojen-paasto-ja-kustannuslaskuri
const fuelTypeEmissions = [
    { type: FuelType.diesel, perUnit: 2.689 },
    { type: FuelType.biodiesel, perUnit: 1.912 },
    { type: FuelType.gas, perUnit: 0 },
    { type: FuelType.electric, perUnit: 0.096 },
    { type: FuelType.hydrogen, perUnit: 0 },
]

/** @type {import("@mui/x-data-grid").GridFilterModel} */
const initialFilterModel = { items: [{ field: "invalid", operator: "is", value: "false" }] }

export default function EmissionReports() {
    /** @type {UserConfig<import("../components/EmissionMultipliers").EmissionMultipliersConfig>} */
    const multipliersConfig = useUserConfig('emissionMultipliers');

    const reportRef = useRef();

    const printAreaRef = useRef();

    const [contWidth, setContWidth] = useState(0);

    const [reportData, setReportData] = useState(/** @type {import("../components/emissionreports/EmissionsOverview").EmissionReportData} */(null));
    const [reportJobs, setReportJobs] = useState(/** @type {import("../components/emissionreports/EmissionReportHelpers").EmissionReportJob[]} */([]));
    const [summaryData, setSummaryData] = useState(/** @type {import("../components/emissionreports/EmissionReportHelpers").EmissionSummary | null} */(null))
    
    const [fuelMultipliers, setFuelMultipliers] = useState(multipliersConfig?.config?.multipliers ? multipliersConfig.config.multipliers : fuelTypeEmissions);
    const [showMultipliersDialog, setShowMultipliersDialog] = useState(false);

    const [allClientsSelected, setAllClientsSelected] = useState(false);
    const [printClientName, setPrintClientName] = useState("");
    const [reportLoading, setReportLoading] = useState(false);
    const [reportShowInvalid, setReportShowInvalid] = useState(false);

    const apiRef = useGridApiRef();

    const [reportConfig, setReportConfig] = useState({
        range: [
            subDays(new Date(), 14),
            new Date()
        ],
        clients: [],
    })

    const [printTriggered, setPrintTriggered] = useState(false);

    const [clients, setClients] = useState([]);

    const handlePrint = useReactToPrint({
        content: () => printAreaRef.current,
        pageStyle: "@print { size: A4 landscape; }",
        onAfterPrint: () => { setPrintTriggered(false) }
    });

    useEffect(() => {
        if (printTriggered) {
            handlePrint();
        }
    }, [printTriggered]);

    useEffect(() => {
        setAllClientsSelected(clients?.length > 0 && reportConfig?.clients?.length === clients?.length);

        const postConfig = { ...reportConfig };

        // Change client to a value understood by API
        postConfig.clients = postConfig.clients.map(c => c.id);

        console.log(postConfig);
        setReportLoading(true);

        LogitarApi.getEmissionReports({
            from: postConfig.range[0],
            to: postConfig.range[1],
            clients: postConfig.clients,
        })
            .then((resp) => {
                const nReport = resp.report;
                if (nReport.clientName) {
                    // Set client name
                    nReport.clientName = (clients.find(e => e.id == nReport.clientName) || { label: "" }).label;
                }
                // setReportData(resp.report);
                if (resp?.report?.jobs) {
                    setReportJobs(resp.report.jobs);
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => setReportLoading(false))
    }, [reportConfig]);

    useEffect(() => {
        if (LogiTarUser?.current?.isClient()) {
            setClients(LogiTarUser?.current?.info?.clients?.map(c => 
                ({
                    id: c.id,
                    label: c.name,
                    companyName: c.companyName
                })
            ));
        } else {
            LogitarApi.getClients()
                .then((resp) => {
                    setClients(resp.clients.map((e, i) => {
                            return {
                                id: e.id,
                                label: e.name,
                                companyName: e.companyName
                            }
                        })
                    );
                })
                .catch((err) => {
                    console.error(err);
                })
        }

    }, [])

    useLayoutEffect(() => {
        setContWidth(reportRef.current.clientWidth)
    }, [reportRef])

    useEffect(() => {
        setSummaryData(calculateEmissionSummary(reportJobs, fuelMultipliers, reportConfig));
    }, [reportJobs, fuelMultipliers, reportConfig])

    // Select all clients by default
    useEffect(() => {
        setReportConfig({ ...reportConfig, clients: clients });
        if (clients.length > 0 && LogiTarUser.current.isClient()) {
            setPrintClientName(clients.map(c => c.companyName).reduce((p, c) => c.length < p.length ? c : p));
        }
    }, [clients])

    /** @type {import("@mui/x-data-grid").GridColDef[]} */
    const columns = useMemo(() => [
        { field: "billingDate", headerName: "Laskutuspäivä", flex: 1, type: "date",
            valueGetter: (params) => params.value ? new Date(params.value) : null,
            valueFormatter: (params) => params.value ? format(params.value, "dd.MM.yyyy") : "" },
        { field: "itemName", headerName: "Nimike", flex: 3 },
        { field: "departureName", headerName: "Lastauspaikka", flex: 2 },
        { field: "arrivalName", headerName: "Purkupaikka", flex: 2 },
        { field: "cargoLabel", headerName: "Tuote", flex: 2 },
        { field: "vehicleLicense", headerName: "Rek.nro", flex: 1 },
        { field: "distance", headerName: "Km A→B→C", flex: 1, type: "number", headerAlign: "left", renderHeader: (params) => 
            <Tooltip title={<Box><Box>Kilometrit A→B→C</Box><Box>A: Lastaus</Box><Box>B: Purku</Box><Box>C: Seuraava lastaus</Box></Box>}>
                <span style={{ fontWeight: 500, textAlign: "left" }}>{params.colDef.headerName}</span></Tooltip>,
            valueGetter: (params) => params.value ? params.value : null },
        { field: "tons", headerName: "Tonnit", flex: 1, type: "number" },
        { field: "fuel", headerName: "Kulutus", flex: 1, type: "number",
            valueGetter: (params) => params.value ? params.value : null,
            valueFormatter: (params) => {
                const row = params.api.getRow(params.id); // valueFormatter doesn't get params.row
                return params.value && fuelTypeList.find(t => t.value === (row?.shiftFuelType || row?.vehicleFuelType)) 
                ? params.value + " " + fuelTypeList.find(t => t.value === (row?.shiftFuelType || row?.vehicleFuelType))?.unit : null }},
        { field: "shiftFuelType", headerName: "Polttoaine",
            valueGetter: (params) => params.value ? fuelTypeList.find(t => t.value === params.value)?.label 
            : fuelTypeList.find(t => t.value === params?.row?.vehicleFuelType)?.label },
        { field: "co2", headerName: "CO2", flex: 1, renderHeader: () => <span style={{ fontWeight: 500, marginTop: "5px" }}>CO<sub>2</sub></span>,
            valueGetter: (params) => calculateEmissionsForFuel(params.row, fuelMultipliers),
            valueFormatter: (params) => params.value != null ? `${Math.round(params.value * 100) / 100} kg` : null},
        { field: "invalid", headerName: "Virheellinen", renderHeader: () => "", align: "center", headerAlign: "center", width: 50, type: "boolean",
            valueGetter: (params) => isJobInvalid(params.row) !== false ? true : false,
            renderCell: (params) => renderRowInvalidReason(params)}
    ], [fuelMultipliers]);

    return <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 1,
            pr: 2,
            height: '100%',
            gap: 1,
            overflow: 'auto',
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
            }}
        >
            {/* Report content */}
            <Box
                sx={{
                    flex: 1,
                    height: printTriggered ? '100%' : '50vh',
                    width: printTriggered ? undefined : '50vw',
                    maxWidth: printTriggered ? undefined: '100vh',
                    minWidth: '700px',
                    minHeight: '450px',
                    overflowY: 'hidden'
                }}
                ref={reportRef}
            >
                {
                    contWidth > 0 && summaryData &&
                    <PrintableReportBase
                        width={printTriggered ? 'a4' : contWidth}
                        ref={printAreaRef}
                        printTriggered={printTriggered}
                    >
                        <EmissionsOverview
                            data={{ ...summaryData, clientName: printClientName }}
                        />
                    </PrintableReportBase>
                }
            </Box>

            {/* Report settings */}
            <Box
                sx={{
                    minWidth: 300
                }}
            >
                <Paper sx={{
                    padding: 1,
                    // m: 1,
                    // mr: 2,
                    "& .MuiTextField-root": {
                        margin: 1
                    },
                    "& .MuiStack-root": {
                        "& .MuiTypography-root": {
                            mx: 1
                        },
                    },
                    "& .MuiAutocomplete-root": {
                        margin: 1,
                        "& .MuiTextField-root": {
                            margin: 0
                        }
                    }
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="h6" sx={{ flex: 1 }}>
                            Päästöraportti
                        </Typography>
                        {
                            /*
                                <Typography variant="h6" sx={{ flex: 1, textAlign: 'right', color: '#33b3de' }}>
                                    {invoiceData.invoice.clients[invoiceClient].name}
                                </Typography>
                            */
                        }
                    </Box>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Box>
                        <DateRangeSelect
                            sx={{ width: 300 }}
                            value={reportConfig.range}
                            onChange={(val) => {
                                setReportConfig({ ...reportConfig, range: val });
                            }}
                            labels={{ start: "Ajopvm alku", end: "Ajopvm loppu" }}
                        />
                        <Autocomplete
                            multiple
                            options={[{ id: -1, label: allClientsSelected ? "Poista valinnat" : "Valitse kaikki" }, ...clients]}
                            value={reportConfig.clients}
                            isOptionEqualToValue={(opt, val) => opt.id === val.id}
                            getOptionLabel={(opt) => opt.label}
                            renderInput={(params) => <TextField label={"Asiakaskoodit"} {...params} />}
                            onChange={(e, v) => {
                                if (v.some(c => c.id === -1)) {
                                    if (allClientsSelected) {
                                        setReportConfig({ ...reportConfig, clients: [] });
                                    } else {
                                        setReportConfig({ ...reportConfig, clients: clients });
                                    }
                                } else {
                                    setReportConfig({ ...reportConfig, clients: v });
                                }
                            }}
                            disableClearable
                            sx={{ width: 284, maxHeight: 284 }}
                            limitTags={10}
                            size="small"
                            renderTags={(value, getTagProps) => {
                                const numTags = value.length;
                                const limitTags = 10;
                                return (
                                    <>
                                        {value.slice(0, limitTags).map((option, index) => (
                                            <Chip
                                                {...getTagProps({ index })}
                                                key={index}
                                                label={option.label}
                                                size="small"
                                            />
                                        ))}
                        
                                        {numTags > limitTags && ` +${numTags - limitTags}`}
                                    </>
                                );
                            }}
                        />
                        <TextField
                            label="Raportin otsikko"
                            size="small"
                            value={printClientName}
                            onChange={(e) => setPrintClientName(e.target.value)}
                            sx={{ width: 284 }}
                        />
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, mt: 2 }}>
                            <Button
                                variant="contained"
                                onClick={() => setPrintTriggered(true)}
                            >
                                <Print />
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => setShowMultipliersDialog(true)}
                                sx={{ flex: 1 }}
                                startIcon={<ListAlt/>}
                            >
                                Päästökertoimet
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
        {/* Report jobs table */}
        <Box sx={{ minHeight: "25rem" }}>
            <DataGridPro
                apiRef={apiRef}
                columns={columns}
                rows={reportJobs}
                density="compact"
                loading={reportLoading}
                localeText={localeText}
                onStateChange={() => {
                    const filteredIds = gridFilteredSortedRowIdsSelector(apiRef);
                    const filteredJobs = reportJobs.filter(j => filteredIds.includes(j.id));
                    setSummaryData(calculateEmissionSummary(filteredJobs, fuelMultipliers, reportConfig));
                }}
                slots={{
                    toolbar: () => <CustomToolbar hideButton hidePrinting customElement={
                        <Button size="small" variant="outlined" onClick={() => {
                            if (reportShowInvalid) {
                                apiRef.current.setFilterModel(initialFilterModel);
                            } else {
                                apiRef.current.setFilterModel({ items: [] });
                            }
                            setReportShowInvalid(prev => !prev);
                        }}>{reportShowInvalid ? "Näytä raportin rivit" : "Näytä kaikki rivit"}</Button>
                    } />
                }}
                initialState={{
                    filter: { filterModel: initialFilterModel },
                    columns: { columnVisibilityModel: { itemName: false } }
                }}
            />
        </Box>
        <EmissionMultipliers
            open={showMultipliersDialog}
            multipliers={fuelMultipliers}
            onClose={() => setShowMultipliersDialog(false)}
            onAccept={(multipliers) => {
                setFuelMultipliers(multipliers);
                setShowMultipliersDialog(false);
            }}
            onReset={() => {
                setFuelMultipliers(fuelTypeEmissions);
                setShowMultipliersDialog(false);
            }}
        />
    </Box>
}